import React from 'react'
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import COLORS from '../constants/colors'
import FONTS from '../constants/fonts'
import Divider from './Divider'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    height: 'fit-content',
    backgroundColor: COLORS.WHITE,
    justifyContent: 'space-evenly',
  },
  sectionLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 360,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      maxWidth: '100%',
      paddingTop: 36,
      height: 120,
    },
  },
  sectionRight: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%', // Ensure it takes the full width of the grid area
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
    },
  },
  text: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  header: {
    fontSize: 38,
    // marginTop: 36,
    maxWidth: 360,
    marginBlockStart: 0,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXM,
      marginBottom: 0,
    },
  },
  video: {
    width: '100%',
    height: '100%',
    height: 400,
    maxWidth: '100%',
    borderRadius: 4,
  },
}))

const TextAndVideo = ({ textLeft }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down(750))

  return (
    <div className={classes.root}>
      <Divider height={smallScreen ? 20 : 120} />
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={4} className={classes.sectionLeft}>
          <h2 className={`${classes.text} ${classes.header}`}>{textLeft}</h2>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.sectionRight}>
          <iframe
            className={classes.video}
            src="https://www.youtube.com/embed/GlLmRpiU65E?si=OGvDmWFof_ByIL9i&rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
      </Grid>
      <Divider height={smallScreen ? 20 : 120} />
    </div>
  )
}

export default TextAndVideo
